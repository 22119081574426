import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Cloudinary } from '@cloudinary/url-gen';
import { Cloudinary as Cld } from 'cloudinary-core';

import algoliaInsights from 'search-insights';
import { compose } from 'redux';

import css from './AlgoliaListingCard.module.css';

algoliaInsights('init', {
  appId: process.env.REACT_APP_APPLICATION_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY,
});

const MIN_LENGTH_FOR_LONG_WORDS = 30;

const { Money } = sdkTypes;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const AlgoliaListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    hit,
    showAuthorInfo,
    config,
    currentUser,
    filterActivated,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const {
    author,
    createdAt,
    metaData,
    title,
    description,
    price,
    publicData,
    objectID,
    __position,
    __queryID,
  } = hit;
  const listing = {
    id: objectID,
    title,
    description,
    metaData,
    createdAt,
    publicData,
    price,
  };

  const ensuredListing = ensureListing(listing);
  const listingId = ensuredListing.id;
  const {
    title: listingTitle,
    description: listingDescription,
    price: listingPrice,
    publicData: listingPublicData,
  } = ensuredListing || {};
  const { productImageIds, brand, condition, categoryLevel1, categoryLevel2, gender, clothsSize } =
    listingPublicData || {};
  const listingImage = productImageIds[0];

  const slug = createSlug(listingTitle);
  const ensuredAuthor = ensureUser(author);

  // console.log(listingTitle, "<>>>>>>>>>>><><><><><><><><" , productImageIds);

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  // Create and configure your Cloudinary instance.
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
    },
  });
  const cloudinary = new Cld({ cloud_name: process.env.REACT_APP_CLOUD_NAME, secure: true });

  const myImage = cld.image(listingImage);
  const imageURL = cloudinary.url(listingImage, { secure: true });

  return (
    <NamedLink
      className={classes}
      name="ListingPage"
      params={{ id: listingId, slug }}
      to={{
        search: `queryId=${__queryID}&objectId=${objectID}&filterActivated=${filterActivated}`,
      }}
      onClick={() => {
        algoliaInsights('clickedObjectIDsAfterSearch', {
          userToken: currentUser && currentUser.id ? currentUser.id.uuid : 'anonymous-user',
          eventName: 'Product Clicked',
          index: process.env.REACT_APP_ALGOLIA_LISTING_INDEX,
          objectIDs: [objectID],
          queryID: __queryID,
          positions: [__position],
          addtionalData: {
            UserID: currentUser && currentUser.id ? currentUser.id.uuid : '',
            ListingID: listing && listing.id && listing?.id?.uuid,
            brand: brand?.label ?? '',
            categoryLevel1: categoryLevel1,
            categoryLevel2: categoryLevel2,
            condition: condition?.label ?? '',
            gender: gender?.label ?? '',
            clothsSize: clothsSize?.label ?? '',
          },
        });
      }}
    >
      <div className={css.aspectRatioWrapper}>
        <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
          <ResponsiveImage
            alt={listingId}
            imgUrl={imageURL}
            variants={[]}
            aspectWidth={aspectWidth}
            aspectHeight={aspectHeight}
            isResponsiveImage={false}
            isSearchPage={true}
          />
        </AspectRatioWrapper>
      </div>
      <div className={css.info}>
        <PriceMaybe
          price={new Money(listingPrice.amount * 100, listingPrice.currency)}
          publicData={listingPublicData}
          config={config}
          intl={intl}
        />
        <div className={css.mainInfo}>
          <div className={css.title}>
            {(listingTitle && listingTitle.length) > MIN_LENGTH_FOR_LONG_WORDS
              ? listingTitle.slice(0, MIN_LENGTH_FOR_LONG_WORDS).concat('...')
              : listingTitle}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              {brand.label}
              {/* | {condition.label} */}
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

AlgoliaListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

AlgoliaListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  // hit: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default compose(injectIntl)(AlgoliaListingCardComponent);
